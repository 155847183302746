//
//  Product tabs
//  ---------------------------------------------

.product.data.items {
    @include min-screen($screen__m) {
        @include lib-data-tabs();

        & > .item.title {
            & > .switch {
                text-transform: uppercase;
            }
            &.active {
                & > .switch {
                    background-color: #d0dae6;
                }
            }
        }
    }
    @include max-screen($screen__m) {
        @include lib-data-accordion();

        & > .item.title {
            display: inline-block;

            & > .switch {
                text-transform: uppercase;
            }
        }
    }
}
