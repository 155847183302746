//
//  Variables
//  _____________________________________________

$minicart__border-color       : $color-gray80;
$minicart__padding-horizontal : $indent__base;

$minicart-qty__height         : 24px;

//
//  Minicart
//  ---------------------------------------------

//
//  Mobile
//  _____________________________________________

.block-minicart {
    .items-total {
        float: left;
        margin: 0 $indent__s;

        .count {
            font-weight: $font-weight__bold;
        }
    }

    .subtotal {
        margin: 0 $indent__s $indent__s;
        text-align: right;

        .label {
            @extend .abs-colon;
        }
    }

    .amount {
        .price-wrapper {
            &:first-child {
                .price {
                    font-size: $font-size__l;
                    font-weight: $font-weight__bold;
                }
            }
        }
    }

    .subtitle {
        display: none;

        &.empty {
            display: block;
            font-size: 14px;
            padding: $indent__l 0 $indent__base;
            text-align: center;
        }
    }

    .text {
        &.empty {
            text-align: center;
        }
    }

    .block-content {
        > .actions {
            margin-top: 15px;
            text-align: center;

            > .primary {
                margin: 0 $indent__s 15px;

                .action {
                    &.primary {
                        display: block;
                        margin-bottom: 15px;
                        width: 100%;
                        @include min-screen($screen__l) {
                            @include lib-button-l();
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .block-category-link,
    .block-product-link,
    .block-cms-link,
    .block-banners {
        margin: 15px 0 0;
        text-align: center;
    }
}


.minicart-wrapper {
    @include lib-dropdown(
        $_toggle-selector                     : '.action.showcart',
        $_options-selector                    : '.block-minicart',
        $_dropdown-toggle-icon-content        : false,
        $_dropdown-toggle-active-icon-content : false,
        $_dropdown-list-item-padding          : false,
        $_dropdown-list-item-hover            : false,
        $_icon-font-position                  : before,
        $_icon-font-size                      : 35px,
        $_icon-font-line-height               : 33px,
        $_icon-font-color                     : $minicart-icons-color,
        $_icon-font-color-hover               : $minicart-icons-color-hover,
        $_icon-font-color-active              : $minicart-icons-color
    );
    float: right;
    margin-top: 30px;
    margin-left: $indent__xl;
    @include max-screen($screen__l) {
        margin-left: $indent__base;
    }
    @include max-screen($screen__m) {
        margin-top: $indent__s;
        margin-left: $indent__s;
    }
    @include max-screen($screen__s) {
        margin-top: $indent__base;
        margin-left: 0;
    }
    @include max-screen($screen__xs) {
        float: none;
        margin: 0 $indent__xl;
        position: static;
    }
    .block-minicart {
        @include lib-css(padding, 25px $minicart__padding-horizontal);
        right: 15px;
        width: 390px;
        z-index: 101;
        font-size: $font-size__base;
        @include max-screen($screen__m) {
            right: 0;
        }
        @include max-screen($screen__xs) {
            width: 290px;
            right: 50%;
            top: 109px;
            min-width: auto;
            transform: translateX(50%);

            &:before {
                right: 151px !important;
            }
            &:after {
                right: 150px !important;
            }
        }
        .block-title {
            display: none;
        }

        &:after {
            left: auto;
            right: 25px;
        }

        &:before {
            left: auto;
            right: 26px;
        }
    }

    .product {
        .actions {
            float: right;
            margin: -30px 0 0;
            text-align: right;

            > .primary,
            > .secondary {
                display: inline;
            }
        }
    }

    .action {
        &.close {
            @include lib-button-icon(
                $icon-remove,
                $_icon-font-size        : 32px,
                $_icon-font-line-height : 32px,
                $_icon-font-text-hide   : true
            );
            @include lib-button-reset();
            height: 40px;
            position: absolute;
            right: 0;
            top: 0;
            width: 40px;
        }

        &.showcart {
            white-space: nowrap;
            height: 34px;
            color: $color-white;
            font-weight: 500;
            font-size: 12px;
            .text {
                float: left;
                line-height: 30px;
            }
            .counter {
                @include lib-css(transition, all 0.2s);
                display: inline-block;
                font-size: 13px;
                margin-left: $indent__xs;
                
                &.qty {
                    height: 30px;
                    width: 30px;
                    font-size: 12px;
                    background-color: $color-white;
                    color: $primary__color;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 30px;
                }
                .counter-number:empty:after {
                    content: "0";
                }
                &.subtotal {
                    border-bottom: 1px solid #4a5e70;
                    line-height: 29px;
                    padding-right: ($indent__xl * 2);
                    @include max-screen($screen__l) {
                        padding-right: 0;
                        margin-right: $indent__xl;
                    }
                    @include max-screen($screen__xs) {
                        margin: 0;
                    }
                    span:empty:after {
                        content: "£0.00";
                    }
                }
                .loader {
                    > img {
                        @include lib-css(max-width, $minicart-qty__height);
                    }
                }
                &-label {
                    @extend .abs-visually-hidden;
                }
            }
            &:hover {
                .counter {
                    &.qty {
                        background-color: $secondary__color;
                        color: $color-white;
                    }
                    &.subtotal {
                        border-color: $secondary__color;
                    }
                }
            }
        }
    }

    .minicart-widgets {
        margin-top: 15px;
    }
}

.minicart-items-wrapper {
    @include lib-css(border, 1px solid $minicart__border-color);
    @include lib-css(margin, 0 (-$minicart__padding-horizontal));
    border-left: 0;
    border-right: 0;
    overflow-x: auto;
    padding: 15px;
}

.minicart-items {
    @include lib-list-reset-styles();

    .product-item {
        padding: $indent__base 0;

        &:not(:first-child) {
            @include lib-css(border-top, 1px solid $minicart__border-color);
        }

        &:first-child {
            padding-top: 0;
        }

        > .product {
            @extend .abs-add-clearfix;
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }

    .product-item-pricing {
        .label {
            display: inline-block;
            width: 6rem;
        }
    }

    .price-minicart {
        margin-bottom: $indent__xs;
    }

    .product {
        > .product-item-photo,
        > .product-image-container {
            float: left;
        }

        .toggle {
            @include lib-icon-font(
                $_icon-font-content     : $icon-down,
                $_icon-font-size        : 28px,
                $_icon-font-line-height : 16px,
                $_icon-font-text-hide   : false,
                $_icon-font-position    : after,
                $_icon-font-display     : block
            );
            cursor: pointer;
            position: relative;

            &:after {
                position: static;
                right: $indent__base;
                top: 0;
            }
        }

        &.active {
            > .toggle {
                @include lib-icon-font-symbol(
                    $_icon-font-content  : $icon-up,
                    $_icon-font-position : after
                );
            }
        }
    }

    .product-item-name {
        font-weight: $font-weight__regular;
        margin: 0 0 $indent__s;

        a {
            @include lib-css(color, $link__color);
        }
    }

    .product-item-details {
        padding-left: 88px;

        .price {
            font-weight: $font-weight__bold;
        }

        .price-including-tax,
        .price-excluding-tax {
            margin: $indent__xs 0 0;
        }

        .weee[data-label] {
            @include lib-font-size(11);
            .label {
                @extend .abs-no-display;
            }
        }

        .details-qty {
            margin-top: $indent__s;
        }
    }

    .product.options {
        .tooltip.toggle {
            @include lib-icon-font(
                $icon-down,
                $_icon-font-size        : 28px,
                $_icon-font-line-height : 28px,
                $_icon-font-text-hide   : true,
                $_icon-font-margin      : -3px 0 0 7px,
                $_icon-font-position    : after
            );

            .details {
                display: none;
            }
        }
    }

    .details-qty,
    .price-minicart {
        .label {
            @extend .abs-colon;
        }
    }

    .item-qty {
        margin-right: $indent__s;
        text-align: center;
        width: 40px;
    }

    .update-cart-item {
        @include lib-font-size(11);
        vertical-align: top;
    }

    .subtitle {
        display: none;
    }

    .action {
        &.edit,
        &.delete {
            @include lib-icon-font(
                $icon-settings,
                $_icon-font-size         : 28px,
                $_icon-font-line-height  : 28px,
                $_icon-font-text-hide    : true,
                $_icon-font-color        : $color-gray19,
                $_icon-font-color-hover  : $color-gray19,
                $_icon-font-color-active : $color-gray19
            );
        }

        &.delete {
            @include lib-icon-font-symbol(
                $_icon-font-content : $icon-trash
            );
        }
    }
}
