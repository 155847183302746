//
//  Product Lists
//  _____________________________________________
//  Pricing style changes by PT

.related-products {
    .block.related {
        .block-title {
            border-bottom: 1px solid #e7ecf2;
            padding-bottom: $indent__s;
            margin-bottom: $indent__s;
            margin-top: $indent__base;

            .go-to-top {
                float: right;
                font-size: 15px;
                color: $primary__color;
                text-transform: uppercase;
                font-weight: 500;
                letter-spacing: 0.02em;

                @include max-screen($screen__s) {
                    display: none !important;
                }
                .fas {
                    position: relative;
                    top: 1px;
                }
            }
            strong {
                font-weight: 500;
                font-size: 15px;
                text-transform: uppercase;
            }
        }
        .block-actions {
            display: none;
        }
    }
}

.products-grid,
.products-list {
    margin: $indent__base 0 0;

    .product {
        &-items {
            @extend .abs-reset-list;
            @include make-row;
            margin-left: 0;

            .product-item {
                margin-bottom: $indent__m;

                .homepage-featured & {
                    margin: 0;
                }
            }
        }
        &-item {
            .page-products .page-main & {
                @include make-xxs-column(12);
                @include make-xs-column(6);
                @include make-xl-column(4);
                padding-left: 0;
            }
            .page-main .block.crosssell &,
            .page-main .block.related & {
                @include make-xxs-column(12);
                @include make-xs-column(6);
                @include make-m-column(4);
                @include make-l-column(3);
                padding-left: 0;

                @include min-screen($screen__xl) {
                    width: 20%;
                }
            }
            .page-main .block-new-products & {
                @include make-xxs-column(12);
                @include make-xs-column(6);
                @include make-l-column(4);
                @include make-xl-column(3);
                padding-left: 0;

                @include max-screen($screen__xl) {
                    &:first-child + li + li + li {
                        display: none;
                    }
                }
                @include max-screen($screen__l) {
                    &:first-child + li + li {
                        display: none;
                    }
                }
            }
            &-info {
                max-width: 100%;
                border: 1px solid #e7ecf2;
                padding: $indent__base;

                .homepage-featured & {
                    border: 0;
                }
                .block.related & {
                    border: 0;
                    padding: 0;
                }
            }
            &-photo {
                display: block;
                margin-bottom: $indent__s;
                img {
                    @include lib-css(transition, 0.2s all);
                    &:hover {
                        transform: scale(1.02);
                    }
                }
            }
            .product-reviews-summary {
                margin-bottom: $indent__xs;

                .reviews-actions {
                    display: none;
                }
            }
            &-name {
                @extend .abs-product-link;
                display: block;
                margin-bottom: $indent__xs;
                word-wrap: break-word;
                hyphens: auto;
                font-size: 14px;
                font-weight: 500;
                overflow: hidden;
                height: 38px;

                @include max-screen($screen__l) {
                    height: 57px;
                }
            }
            &-sku {
                margin-bottom: $indent__s;
                color: #94acc6;
                font-size: 13px;
            }
            .field.choice.related {
                display: none;
            }
            .stock {
                font-weight: 500;
                font-size: 12px;
                margin-bottom: $indent__s;
                .homepage-featured &,
                .block.related & {
                    display: none;
                }
                &.available {
                    color: #42af00;
                }
                &.unavailable {
                    color: #f61717;
                }
            }
            &-description {
                position: relative;
                height: 18px;
                font-size: 13px;
                margin-bottom: $indent__s;

                p {
                    background-color: #ffffff;
                    @include lib-css(transition, 0.2s all);
                    position: absolute;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 100%;
                    height: 18px;
                    cursor: default;
                    margin: 0;
                }
                .homepage-featured &,
                .block.related & {
                    display: none;
                }
            }
            .price-box {
                position: relative;
                padding-bottom: 21px;

                .price-container {
                    .price-label {
                        display: none;
                    }
                    .price-including-tax {
                        white-space: nowrap;
                        color: $tertiary__color;
                        .price {
                            font-size: 16px;
                            font-weight: 500;

                            .block.related & {
                                font-size: 15px;
                            }
                        }
                        &:after {
                            content: ' ' attr(data-label);
                            font-size: 75%;
                        }
                    }
                    .price-excluding-tax {
                        white-space: nowrap;
                        color: $tertiary__color;
                        .price {
                            font-size: 15px;
                            font-weight: 300;

                            .block.related & {
                                font-size: 13px;
                            }
                        }
                        &:before {
                            content: '(';
                        }
                        &:after {
                            content: ' ' attr(data-label) ')';
                            font-size: 75%;
                        }
                    }
                }
                .special-price {
                    .price-excluding-tax {
                        color: $special__color;
                    }
                    .price-including-tax {
                        color: $special__color;
                    }
                }
                .old-price {
                    position: absolute;
                    bottom: 0;
                    left: 0;

                    .price-excluding-tax {
                        display: none;
                        
                        &:before {
                            content: 'Was ';
                        }
                        &:after {
                            content: ' ' attr(data-label);
                        }
                    }
                    .price-including-tax {
                        &:before {
                            content: 'Was ';
                        }
                        &:after {
                            content: ' ' attr(data-label);
                        }
                    }
                }
                .price-from,
                .price-to {
                    margin: 0;
                }
                .minimal-price-link {
                    display: none;
                }
            }
            &-actions {
                margin-top: $indent__s;

                .homepage-featured & {
                    display: none;
                }
                .actions-primary {
                    .action {
                        font-size: 11px;
                        line-height: 16px;
                        background-color: $secondary__color;
                        border-color: $secondary__color;
                        padding: 6px 10px;

                        &:hover {
                            background-color: $tertiary__color;
                            border-color: $tertiary__color;
                        }
                    }

                }
                .actions-secondary {
                    display: none;
                }
            }
        }
    }
    .slick-dots {
        @extend .abs-reset-list;
        position: absolute;
        text-align: center;
        bottom: -71px;
        left: 50%;
        transform: translateX(-50%);
        @include max-screen($screen__xl) {
            bottom: -51px;
        }
        @include max-screen($screen__m) {
            left: 0;
            width: 100%;
            transform: none;
        }
        li {
            display: inline-block;
            margin: 0 7px;
            button {
                display: block;
                @include lib-button-reset();
                width: 11px;
                height: 11px;
                border-radius: 50%;
                background-color: #e4eaf0;
                text-indent: -999em;
                overflow: hidden;
                &:hover {
                    background-color: $footer-background-light__color;
                }
            }
            &.slick-active {
                button {
                    background-color: $footer-background-light__color;
                }
            }
        }
    }
}

@include min-screen($screen__xl) {
    .products-list {
        .product {
            &-items {
                margin: 0;
            }
            &-item {
                .page-products .page-main & {
                    padding: 0;
                    width: 100%;
                }
                &-info {
                    &-content {
                        @include abs-add-clearfix();
                        position: relative;
                    }
                }
                &-name {
                    height: auto;
                }
                &-photo {
                    float: left;
                    width: 160px;
                    margin: 0 $indent__base 0 0;
                }
                &-description {
                    width: 75%;
                    height: auto;

                    p {
                        white-space: normal;
                        position: static;
                        width: auto;
                        overflow: auto;
                        text-overflow: clip;
                        height: auto;

                        &:before {
                            display: none;
                        }
                    }
                }
                &-inner {
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 100%;
                    border-left: 1px solid #e7ecf2;
                    padding: 0 $indent__l;
                }
            }
        }
    }
}
