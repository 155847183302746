//
//  Variables
//  _____________________________________________

$autocomplete__background-color  : $color-white;
$autocomplete__border            : 1px solid $form-element-input__border-color;
$autocomplete-item__border       : 1px solid $color-gray90;
$autocomplete-item__hover__color : $color-gray91;
$autocomplete-item-amount__color : $color-gray60;

.block-search {
    float: right;
    margin-bottom: 0;
    @include max-screen($screen__s) {
        width: 100%;
    }
    .block {
        &-title {
            display: none;
        }
        &-content {
            margin-bottom: 0;
        }
    }
}

.minisearch {
    float: right;
    position: relative;
    margin-top: 26px;
    @include max-screen($screen__m) {
        margin-top: $indent__xs;
        margin-bottom: $indent__s;
    }
    @include max-screen($screen__s) {
        width: 100%;
    }
    .label {
        display: none;
    }

    .control {
        overflow: hidden;
    }

    #minisearch-input-top-search {
        @include lib-input-placeholder(#798fa7);
        background-color: $footer-background-dark__color;
        border-color: $footer-background-dark__color;
        color: #798fa7;
        height: 39px;
        width: 221px;
        box-shadow: none;
        margin: 0;
        padding-right: 39px;
        z-index: 3;
        @include max-screen($screen__s) {
            width: 100%;
        }
    }

    .action.search {
        @include lib-button-reset();
        background-image: url('../images/icon-search.png');
        background-position: center;
        background-repeat: no-repeat;
        text-indent: -999em;
        position: absolute;
        right: 0;
        top: 0;
        height: 39px;
        width: 39px;
        z-index: 5;
        &:hover {
            background-image: url('../images/icon-search.png');
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    .nested {
        display: block;
        padding-top: 5px;
        position: absolute;
    }
}

.search-autocomplete {
    @extend .abs-add-box-sizing;
    display: none;
    margin-top: 0 !important;
    overflow: hidden;
    position: absolute;
    z-index: 3;

    ul {
        @include lib-list-reset-styles();

        li {
            @include lib-css(border-top, $autocomplete-item__border);
            cursor: pointer;
            margin: 0;
            padding: $indent__xs $indent__xl $indent__xs $indent__s;
            position: relative;
            text-align: left;
            white-space: normal;

            &:not(:empty) {
                border-top: 0;
                @include lib-css(border, $autocomplete__border);
                @include lib-css(background, $autocomplete__background-color);
            }

            &:first-child {
                border-top: none;
            }

            &:hover,
            &.selected {
                @include lib-css(background, $autocomplete-item__hover__color);
            }

            .amount {
                @include lib-css(color, $autocomplete-item-amount__color);
                position: absolute;
                right: 7px;
                top: $indent__xs;
            }
        }
    }
}

.form.search.advanced {
    .fields.range {
        .field {
            &:first-child {
                position: relative;

                .control {
                    padding-right: 25px;

                    &:after {
                        content: ' \2013 ';
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        text-align: center;
                        top: 6px;
                        width: 25px;
                    }
                }
            }

            &:last-child {
                position: relative;

                div.mage-error[generated] {
                    left: 0;
                    position: absolute;
                    top: 32px;
                }
            }

            &.with-addon {
                .control {
                    padding-right: 45px;
                }
            }
        }
    }

    .group.price {
        .addon {
            .addafter {
                background: none;
                border: 0;
                padding-top: 6px;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}

.search.summary {
    margin-bottom: $indent__s;
}
