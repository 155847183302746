//
//  Typography
//  ---------------------------------------------

.hidden {
    display: none;
}

.required-captcha {
    display: none;
}

.items {
    @include lib-list-reset-styles();
}

.page-title {
    @include max-screen($screen__m) {
        margin-bottom: $indent__s;
    }
}

p,
ul li {
    font-weight: 300;
}

p {
    @include lib-clearfix();
}

.cms-page-view {
    p {
        img {
            margin-bottom: $indent__base;
            margin-right: $indent__base;
        }
    }
}

dt {
    font-weight: 500;
}

h3 {
    @include max-screen($screen__m) {
        margin-top: $indent__s;
    }
}

cite {
    font-style: italic;
}
