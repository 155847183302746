.breadcrumbs {
    @include lib-breadcrumbs();

    @include max-screen($screen__m) {
        margin: $indent__s 0;
    }
    .item {
        a {
            font-weight: 300;
        }
        &:not(:last-child) {
            &:after {
                font-weight: bold;
                margin-top: -1px;
            }
        }
    }
}
