//
//  Cookie Bar
//  ---------------------------------------------

#hb-cc-wrap {
    display: none;
    padding: 10px 20px;
    text-align: center;
    background: $primary__color;
    color: #ffffff;
    border-bottom: 1px solid #223648;
    @include lib-font-size(13);

    a {
        color: #94acc6;
    }
}

//
//  New Arrivals
//  ---------------------------------------------

.block-new-products {
    .page-layout-3columns & {
        display: none;
    }
    .block-title {
        font-size: 18px;
        text-transform: uppercase;
        color: $primary__color;

        strong {
            font-weight: 500;
        }
    }
}

//
//  Category List
//  ---------------------------------------------

.category-list {
    margin-top: $indent__base;

    a {
        display: block;
        margin-bottom: $indent__l;
        border: 1px solid #e7ecf2;

        img {
            display: block;
            width: 80%;
            padding: 10%;
        }
        span {
            display: block;
            background-color: $quarteriary__color;
            font-weight: 500;
            color: $primary__color;
            padding: $indent__m $indent__base;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            &:before {
                float: right;
                @extend .fas;
                content: fa-content($fa-var-chevron-right);
                font-size: 12px;
                margin-top: 4px;
                margin-left: $indent__s;
            }
        }
        &:hover {
            text-decoration: none;

            span {
                color: #0960b3;
            }
        }
    }
}

//
//  Sidebar List
//  ---------------------------------------------

.sidebar-list {
    @include max-screen($screen__m) {
        display: none;
    }

    ul {
        @extend .abs-reset-list;

        > li {
            > a {
                display: block;
                color: $primary__color;

                &:hover,
                &:focus {
                    text-decoration: none;
                    color: #0960b3;
                }
            }
        }
    }
    .level1 {
        > li {
            > a {
                padding: $indent__base 0;
                border-top: 1px solid $border__color;
                font-weight: 500;

                @include max-screen($screen__m) {
                    padding: $indent__s 0;
                }
            }
            &.has-subs {
                > a {
                    &:before {
                        float: right;
                        @extend .fas;
                        content: fa-content($fa-var-chevron-down);
                        font-size: 12px;
                        margin-top: 4px;
                    }
                }
            }
            &.active {
                > ul {
                    display: block;
                }
                &.has-subs {
                    > a {
                        &:before {
                            content: fa-content($fa-var-chevron-up);
                        }
                    }
                }
            }
            &:first-child {
                > a {
                    border: 0;
                }
            }
        }
    }
    .level2 {
        display: none;

        > li {
            > a {
                margin-bottom: $indent__base;
                font-size: 13px;
                font-weight: 300;

                @include max-screen($screen__m) {
                    margin-bottom: $indent__s;
                }
            }
        }
    }
}

//
//  Homepage Brands
//  ---------------------------------------------

.homepage-brands {
    padding: $indent__xl 0;

    @include max-screen($screen__m) {
        padding: $indent__base 0;
    }

    .brands {
        display: none;
        padding: 0 $indent__xl;

        .slick-track {
            display: table;
        }
        a {
            display: table-cell;
            vertical-align: middle;
            float: none;
            font-size: 0;
            text-align: center;

            img {
                display: inline-block;
                max-width: 80%;

                &:hover {
                    opacity: 0.9;
                }
            }
        }
    }
}

//
//  Homepage Pods
//  ---------------------------------------------

.homepage-pods {
    @extend .abs-add-clearfix;
    background-color: #ebf1f8;
    padding: ($indent__base * 3) 0 ($indent__base * 3 - 7);
    @include max-screen($screen__xl) {
        padding: ($indent__base * 2) 0 ($indent__base * 2 - 7);
    }
    @include max-screen($screen__m) {
        padding: $indent__base 0 ($indent__base - 7);
    }
    .container-fluid {
        padding-left: 16px;
        padding-right: 16px;
    }
    .row {
        margin-right: 0;
        margin-left: -7px;
    }
    .left,
    .right {
        position: relative;
        margin-bottom: 7px;
        padding-right: 0;
        padding-left: 7px;
    }
    .left {
        float: left;
        img {
            float: left;
        }
    }
    .right {
        float: right;
        img {
            float: right;
        }
    }
    .nm {
        margin-bottom: 0;
    }
    a {
        position: absolute;
        bottom: 10%;
        left: 50%;
        transform: translateX(-50%);
    }
}

//
//  Homepage Featured
//  ---------------------------------------------

.homepage-featured {
    padding: ($indent__base * 3) 0 ($indent__base * 6);
    @include max-screen($screen__xl) {
        padding: ($indent__base * 2) 0 ($indent__base * 4);
    }
    @include max-screen($screen__m) {
        padding: $indent__base 0 ($indent__base * 4);
    }
    .homepage-featured-line {
        background-image: url('../images/line.gif');
        background-repeat: repeat-x;
        background-position: center;
        text-align: center;
        h2 {
            display: inline-block;
            font-family: "Bank Gothic BT";
            font-size: 22px;
            font-weight: 500;
            text-transform: uppercase;
            margin: 0;
            background-color: $color-white;
            padding: 0 $indent__base;
            @include max-screen($screen__m) {
                padding: 0 $indent__s;
                font-size: 18px;
            }
        }
    }
    .block {
        margin: 0;
    }
}

//
//  Homepage Focus
//  ---------------------------------------------

.homepage-focus {
    background-color: #ebf1f8;
    padding: ($indent__base * 3) 0;
    @include max-screen($screen__xl) {
        padding: ($indent__base * 2) 0;
    }
    @include max-screen($screen__l) {
        padding: $indent__base 0;
    }
    h2 {
        font-family: "Bank Gothic BT";
        font-size: 20px;
        border-bottom: 1px solid #d3d8df;
        padding-bottom: $indent__xs;
        margin-bottom: $indent__xl;
        @include max-screen($screen__xl) {
            margin-bottom: $indent__base;
        }
        @include max-screen($screen__m) {
            margin: $indent__s 0;
        }
    }
    p {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: $indent__xl;
        @include max-screen($screen__xl) {
            margin-bottom: $indent__base;
        }
        @include max-screen($screen__m) {
            margin-bottom: $indent__s;
            font-size: 14px;
        }
    }
    a {
        color: $tertiary__color;
        font-size: 13px;
        text-transform: uppercase;
        font-weight: 500;
        .fas {
            position: relative;
            left: 0;
            margin-left: $indent__xs;
            @include lib-css(transition, all 0.2s);
        }
        &:hover {
            text-decoration: none;
            color: $tertiary__color;
            .fas {
                left: 2px;
            }
        }
    }
}

//
//  Homepage About
//  ---------------------------------------------

.homepage-about {
    position: relative;
    overflow: hidden;
    img {
        display: block;
        width: 100%;
        @include max-screen($screen__m) {
            max-width: none;
            width: auto;
        }
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .container-fluid {
            height: 100%;
            @include lib-vendor-prefix-display(flex);
            justify-content: center;
            align-items: center;
            .box {
                flex: 0 1 auto;
                text-align: center;
                h2 {
                    font-size: 36px;
                    font-family: "Coalition";
                    font-weight: normal;
                    color: $color-white;
                    text-transform: uppercase;
                    margin-top: 0;
                    margin-bottom: $indent__xl;
                    @include max-screen($screen__xl) {
                        margin-bottom: $indent__base;
                    }
                    @include max-screen($screen__l) {
                        font-size: 24px;
                    }
                }
                p {
                    font-size: 16px;
                    font-weight: 500;
                    color: $color-white;
                    margin-bottom: $indent__xl;
                    @include max-screen($screen__xl) {
                        margin-bottom: $indent__base;
                    }
                    @include max-screen($screen__l) {
                        font-size: 13px;
                    }
                }
                .actions {
                    font-size: 0;
                    .action {
                        margin: 0 $indent__m $indent__s;
                    }
                }
            }
        }
    }
}
