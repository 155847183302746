//
//  Theme variables
//  _____________________________________________

//  Messages
$message-global-note__background           : $color-yellow-light2;
$message-global-note__border-color         : $color-yellow-light3;
$message-global-note__color                : $text__color;

$message-global-note-link__color           : $link__color;
$message-global-note-link__color-hover     : $link__hover__color;
$message-global-note-link__color-active    : $link__active__color;

$message-global-caution__background        : $color-red9;
$message-global-caution__border-color      : none;
$message-global-caution__color             : $color-white;

$message-global-caution-link__color        : $link__color;
$message-global-caution-link__color-hover  : $link__hover__color;
$message-global-caution-link__color-active : $link__active__color;

//  Header
$header__background-color                  : false;
$header-icons-color                        : $color-gray56;
$header-icons-color-hover                  : $color-gray20;

$addto-color                               : $text__color__muted;
$addto-hover-color                         : $primary__color;

$minicart-icons-color                      : $header-icons-color;
$minicart-icons-color-hover                : $header-icons-color-hover;

$button__shadow                            : inset 0 2px 1px rgba(0, 0, 0, 0.12);

$h1__margin-bottom__desktop                : $indent__xl;

$panel__height                             : 34px;


body {
    @include lib-css(background-color, $page__background-color);
}

.back-to-top {
    @include lib-css(transition, all 0.2s);
    position: fixed;
    right: 15px;
    bottom: 10%;
    background-color: $secondary__color;
    color: $color-white;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    z-index: 200;
    &:hover {
        background-color: $primary__color;
        color: $secondary__color;
    }
    span {
        display: none;
    }
}

//
//  Header
//  ---------------------------------------------

.page-header {
    background-color: $primary__color;
    position: relative;
    @include lib-css(background-color, $header__background-color);
    z-index: 20;
    background-image: url('../images/header-bg.jpg');
    background-position: 400px 0;
    background-repeat: no-repeat;
    @include max-screen($screen__xl) {
        background-position: 100px 0;
    }
}

.panel.header {
    .header.links {
        display: none;
    }
}

.header {
    &.content {
        @extend .abs-add-clearfix;
        @include max-screen($screen__l) {
            position: relative;
        }
        @include max-screen($screen__xs) {
            text-align: center;
        }
    }
    &.toplinks {
        @extend .abs-reset-list;
        float: right;
        margin-top: 30px;
        @include max-screen($screen__l) {
            display: none;
        }
        li {
            margin-left: $indent__xl;
            a {
                font-weight: 500;
                color: $color-white;
                font-size: 12px;
                text-transform: uppercase;
                line-height: 30px;
                &:hover {
                    text-decoration: none;
                    color: $secondary__color;
                }
            }
        }
    }
}

.logo {
    float: left;
    position: relative;
    z-index: 5;
    margin-right: $indent__s;
    margin-top: 24px;
    margin-bottom: $indent__base;
    @include max-screen($screen__s) {
        margin-top: $indent__base;
        margin-bottom: $indent__s;
    }
    img {
        display: block;
        @include max-screen($screen__xs) {
            width: 90%;
        }
    }
    .page-print & {
        float: none;
    }
}

.page-main {
    > .page-title-wrapper {
        .page-title + .action {
            margin-top: $indent__l;
        }
    }
}

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        @include lib-css(background, $color-gray94);
        @include lib-css(padding, $indent__s);
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

.action-skip-wrapper {
    height: 0;
    position: relative;
}

//
//  Panel Message
//  ---------------------------------------------

.panel.message {
    float: left;
    text-transform: uppercase;
    line-height: $panel__height;
    @include max-screen($screen__m) {
        display: none;
    }
    p {
        margin: 0;
        font-size: $font-size__s;
        strong {
            font-weight: 900;
        }
    }
}

// Navigation

.nav.wrapper {
    position: relative;
    border-top: 1px solid #223648;
    @include max-screen($screen__l) {
        display: none;
    }
    .nav.header {
        @extend .abs-add-clearfix;
        ul {
            @extend .abs-reset-list;
            display: flex;
            justify-content: space-between;
            li {
                a {
                    display: block;
                    color: $color-white;
                    font-weight: 500;
                    line-height: 53px;
                    padding: 0 $indent__base;
                    @include max-screen($screen__xl) {
                        padding: 0;
                    }
                    &:hover {
                        text-decoration: none;
                        background-color: #223648;
                    }
                }
            }
        }
    }
}

//
//  Promo
//  ---------------------------------------------

.promo.wrapper {
    background-color: $quarteriary__color;
    .promo.header {
        text-align: center;
        ul {
            @extend .abs-add-clearfix;
            @extend .abs-reset-list;
            padding-top: $indent__m;
            @include max-screen($screen__l) {
                @include make-row();
            }
            @include min-screen($screen__l) {
                display: flex;
                justify-content: space-between;
            }
            li {
                @include make-xxs-column(12);
                @include make-s-column(6);
                font-size: 11px;
                text-transform: uppercase;
                padding-bottom: $indent__m;
                @include min-screen($screen__l) {
                    float: none;
                    width: auto;
                }
                span {
                    margin-right: $indent__s;
                }
            }
        }
    }
}

//
//  Global notice
//  ---------------------------------------------

.message.global {
    p {
        margin: 0;
    }

    &.noscript,
    &.cookie {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }

    &.cookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;

        .actions {
            margin-top: $indent__s;
        }
    }

    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
        background-color: red;
        color: white;

        p {
            font-weight: 400;
        }
        a {
            color: #192f25;
        }
    }
}

//
//  Footer
//  ---------------------------------------------

.page-footer {
    background: $primary__color;
    background-image: url('../images/footer-bg.png');
    background-repeat: no-repeat;
    background-position: left bottom;
    margin-top: auto;
    .footer {
        &.content {
            padding-top: $indent__base;
            padding-bottom: ($indent__base * 3);
            @include max-screen($screen__xl) {
                padding-bottom: ($indent__base * 2);
            }
            @include max-screen($screen__l) {
                padding-bottom: $indent__s;
            }
            h3 {
                font-size: 13px;
                text-transform: uppercase;
                margin-bottom: $indent__base;
                color: $footer-text__color;
                @include max-screen($screen__xs) {
                    margin: $indent__s 0;
                }
                .fas {
                    margin-left: $indent__xs;
                    @include min-screen($screen__xs) {
                        display: none;
                    }
                }
            }
            p {
                font-size: 13px;
                font-weight: 500;
                color: $footer-text__color;
                margin-bottom: $indent__base;
            }
            ul {
                @extend .abs-reset-list;
                @include max-screen($screen__xs) {
                    display: none;
                }
                @include min-screen($screen__xs) {
                    display: block !important;
                    height: auto !important;
                }
                > li {
                    font-size: 13px;
                    font-weight: 500;
                    margin-bottom: ($indent__s * 1.5);
                    @include max-screen($screen__xs) {
                        margin-bottom: $indent__s;
                    }
                    a,
                    strong {
                        color: $footer-text__color;
                    }
                }
            }
            .footer-contact {
                margin-bottom: $indent__s;
                p {
                    margin-top: $indent__base;
                    margin-bottom: $indent__base;
                    @include max-screen($screen__m) {
                        margin-top: $indent__s;
                    }
                    img {
                        margin: 0;
                    }
                }
                h4 {
                    font-size: 14px;
                    color: $footer-text__color;
                    margin: 0;
                }
                h2 {
                    font-size: 21px;
                    color: $footer-text__color;
                    margin: $indent__xs 0;
                }
                h5 {
                    font-size: 13px;
                    color: $footer-text__color;
                    font-weight: normal;
                    margin: 0;
                }
            }
            .footer-social {
                @extend .abs-add-clearfix;
                float: right;
                @include max-screen($screen__l) {
                    float: left;
                }
                ul {
                    @extend .abs-add-clearfix;
                    @include lib-list-reset-styles();
                    display: block;
                    margin-top: $indent__base;
                    > li {
                        float: left;
                        margin-bottom: $indent__s;
                        margin-left: $indent__s;
                        @include max-screen($screen__l) {
                            margin-left: 0;
                            margin-right: $indent__s;
                        }
                        a {
                            display: block;
                            width: 39px;
                            height: 39px;
                            border-radius: 50%;
                            @include lib-css(transition, all 0.2s, 1);
                            color: #8da5be;
                            background-color: #163f69;
                            text-align: center;
                            line-height: 39px;
                            font-size: 15px;
                            &:hover {
                                text-decoration: none;
                                background-color: $secondary__color;
                                color: $primary__color;
                            }
                            span {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        &.bottom {
            .border {
                @extend .abs-add-clearfix;
                padding-top: $indent__base;
                padding-bottom: $indent__l;
                border-top: 1px solid #253545;
                text-align: center;
                @include max-screen($screen__m) {
                    padding-top: $indent__s;
                    padding-bottom: $indent__m;
                }
                ul {
                    @extend .abs-reset-list;
                    float: left;
                    @include max-screen($screen__m) {
                        float: none;
                    }
                    > li {
                        float: left;
                        font-size: 11px;
                        font-weight: normal;
                        margin-right: $indent__base;
                        line-height: 28px;
                        @include max-screen($screen__m) {
                            float: none;
                            display: inline-block;
                            margin: 0 $indent__xs;
                        }
                        a {
                            color: $footer-text__color;
                        }
                    }
                }
                img {
                    display: inline-block;
                    @include min-screen($screen__m) {
                        float: right;
                    }
                    @include max-screen($screen__m) {
                        margin-top: $indent__s;
                    }
                }
            }
        }
    }
}

//
//  Footer Bottom
//  ---------------------------------------------

.page-footer-bottom {
    .footer {
        &.content {
            @extend .abs-add-clearfix;
            padding-top: $indent__base;
            padding-bottom: $indent__m;
            text-align: center;
            small {
                font-size: 11px;
                color: #696f72;
            }
            .copyright {
                display: inline-block;
                @include min-screen($screen__m) {
                    float: left;
                }
            }
            .by {
                display: inline-block;
                @include min-screen($screen__m) {
                    float: right;
                }
                @include max-screen($screen__m) {
                    margin-bottom: $indent__s;
                }
                a {
                    display: inline-block;
                    @include min-screen($screen__s) {
                        float: right;
                        margin-left: $indent__s;
                    }
                }
            }
        }
    }
}

//
// Switcher
//  ---------------------------------------------

.page-header,
.page-footer {
    .switcher {
        margin-right: 10px;

        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding            : 0,
                $_dropdown-list-item-padding          : 0,
                $_dropdown-toggle-icon-content        : $icon-down,
                $_dropdown-toggle-active-icon-content : $icon-up,
                $_icon-font-text-hide                 : true,
                $_icon-font-size                      : 22px,
                $_icon-font-line-height               : 22px,
                $_dropdown-list-min-width             : 160px
            );

            ul.dropdown {
                a {
                    display: block;
                    padding: 8px;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        li {
            font-size: $font-size__s;
            margin: 0;
        }

        .label {
            @extend .abs-visually-hidden;
        }

        strong {
            font-weight: $font-weight__regular;
        }
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header,
.page-footer {
    .widget.block {
        @include lib-css(margin, $indent__base 0);
    }
}

.no-display {
    @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
    td {
        padding: 0;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

    .page-wrapper {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        min-height: 100%; // Stretch content area for sticky footer

        > .breadcrumbs,
        > .top-container,
        > .widget {
            box-sizing: border-box;
            width: 100%;
        }

        .ie10 &,
        .ie11 & {
            height: 100%;
        }
    }

    .page-header {
        .switcher {
            display: inline-block;
        }
    }

    .page-main {
        > .page-title-wrapper {
            .page-title {
                display: inline-block;
            }

            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }
}
