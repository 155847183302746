//
//  Slider
//  ---------------------------------------------

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    &.slick-initialized {
        display: block !important;
        padding: 0;

        + .slick-loading {
            display: none;
        }
    }
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    position: relative;
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

.slick-loading {
    width: 100%;
    text-align: center;
    margin: ($indent__base * 3) 0;
}

.slick-arrow {
    display: block;
    @include lib-css(transition, opacity 0.15s, 1);
    @include lib-button-reset();
    position: absolute;
    text-indent: -999em;
    overflow: hidden;
    width: 21px;
    height: 38px;
    top: 50%;
    margin-top: -19px;
    opacity: 1;
    z-index: 100;
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
}
.slick-next,
.slick-next:hover {
    background: url('../images/arrow-right.png');
    right: 0;
}
.slick-prev,
.slick-prev:hover {
    background: url('../images/arrow-left.png');
    left: 0;
}
.slick-disabled {
    opacity: 0;
    cursor: default;
    &:hover {
        opacity: 0;
    }
}

//
//  Slider Homepage
//  ---------------------------------------------

.homepage-slider {
    .slides {
        display: none;
        @include max-screen($screen__m) {
            padding-bottom: 35px;
        }
    }
    .overlay {
        @include min-screen($screen__m) {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .container-fluid {
        height: 100%;
    }
    .row {
        display: flex;
        height: 100%;
    }
    .slick-active {
        .overlay {
            display: block;
        }
    }
    .box {
        align-self: center;
        text-align: center;
        h3 {
            font-size: 18px;
            text-transform: uppercase;
            color: $color-white;
            font-weight: normal;
            margin: 0;
            @include max-screen($screen__m) {
                color: $primary__color;
                font-size: 16px;
                margin-top: $indent__m;
            }
        }
        h2 {
            font-size: 70px;
            font-family: "Coalition";
            font-weight: normal;
            color: $color-white;
            margin-top: $indent__s;
            @include max-screen($screen__xl) {
                margin: $indent__xs 0 $indent__s;
            }
            @include max-screen($screen__l) {
                font-size: 40px;
            }
            @include max-screen($screen__m) {
                font-size: 30px;
                color: $primary__color;
            }
        }
    }
    .slick-slide {
        img {
            width: 100%;
        }
    }
    .slick-dots {
        @include lib-list-reset-styles();
        position: absolute;
        bottom: 5%;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 0;
        @include max-screen($screen__m) {
            bottom: 0;
        }
        li {
            display: inline-block;
            margin: 0 $indent__xs;
            button {
                display: block;
                width: 15px;
                height: 15px;
                border: 2px solid $color-white;
                background-color: transparent;
                text-indent: -999em;
                overflow: hidden;
                padding: 0;
                @include max-screen($screen__m) {
                    border-color: $primary__color;
                }
                &:hover {
                    background-color: $color-white;
                    @include max-screen($screen__m) {
                        background-color: $primary__color;
                    }
                }
                &:before {
                    display: none;
                }
            }
            &.slick-active {
                button {
                    background-color: $color-white;
                    @include max-screen($screen__m) {
                        background-color: $primary__color;
                    }
                }
            }
        }
    }
    .slick-next,
    .slick-next:hover {
        background: url('../images/arrow-right-white.png');
    }
    .slick-prev,
    .slick-prev:hover {
        background: url('../images/arrow-left-white.png');
    }
}
