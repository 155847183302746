// /**
//  * DISCLAIMER
//  *
//  * Do not edit or add to this file if you wish to upgrade Smile Elastic Suite to newer
//  * versions in the future.
//  *
//  *
//  * @category  Smile
//  * @package   Smile\ElasticsuiteCore
//  * @author    Romain Ruaud <romain.ruaud@smile.fr>
//  * @copyright 2016 Smile
//  * @license   Open Software License ("OSL") v. 3.0
//  */

.search-autocomplete {
    @extend .abs-add-box-sizing;
    position: absolute;
    z-index: 3;
    overflow: hidden;
    margin-top: -15px;

    dl {
        @include lib-list-reset-styles();

        dt {
            @include lib-css(border-top, $autocomplete-item__border);
            cursor: default;
            margin: 0;
            padding: $indent__xs $indent__xl $indent__xs $indent__s;
            position: relative;
            text-align: left;
            white-space: normal;

            &:not(:empty) {
                @include lib-css(background, $autocomplete__background-color);
                @include lib-css(border, $autocomplete__border);
                border-top: 0;
                border-bottom: 0;
            }
            &:first-child {
                border-top: none;
            }
            &:hover,
            &.selected {
                @include lib-css(background, $autocomplete-item__hover__color);
            }
        }
        dd {
            @include lib-css(border-top, $autocomplete-item__border);
            cursor: pointer;
            margin: 0;
            padding: $indent__xs $indent__s;
            position: relative;
            text-align: left;
            white-space: normal;

            &:not(:empty) {
                @include lib-css(background, $autocomplete__background-color);
                @include lib-css(border, $autocomplete__border);
                border-top: 0;
            }
            &:first-child {
                border-top: none;
            }
            &:hover,
            &.selected {
                @include lib-css(background, $autocomplete-item__hover__color);
            }
            .amount {
                @include lib-css(color, $autocomplete-item-amount__color);
                position: absolute;
                right: 7px;
                top: $indent__xs;
            }
            .clear {
                clear:both;
            }
        }
    }
}
