button,
.action {
    @include lib-css(transition, all 0.2s);
    text-transform: uppercase;
}

//
//  Default Button Variables (TRANSPARENT)
//  ---------------------------------------------

$button__color                                : $primary__color;
$button__background                           : transparent;
$button__border                               : 1px solid $primary__color;

$button__hover__color                         : $color-white;
$button__hover__background                    : $primary__color;
$button__hover__border                        : $button__border;

$button__active__color                        : $button__hover__color;
$button__active__background                   : $button__hover__background;
$button__active__border                       : $button__hover__border;

//
//  Default Buttons
//  ---------------------------------------------

.action.default {
    @include lib-button();
}

a.action.default {
    @include lib-link-as-button();
}

//
//  Primary Button Variables
//  ---------------------------------------------

$button-primary__background                   : $primary__color;
$button-primary__border                       : 1px solid $primary__color;
$button-primary__color                        : $color-white;

$button-primary__hover__background            : $tertiary__color;
$button-primary__hover__border                : 1px solid $tertiary__color;
$button-primary__hover__color                 : $button-primary__color;

$button-primary__active__background           : $button-primary__hover__background;
$button-primary__active__border               : $button-primary__hover__border;
$button-primary__active__color                : $button-primary__hover__color;

//
//  Primary Buttons
//  ---------------------------------------------

.action.primary {
    @include lib-button-primary();
}

a.action.primary {
    @include lib-link-as-button();
}

//
//  Secondary Button Variables
//  ---------------------------------------------

$button-secondary__background                   : $secondary__color;
$button-secondary__border                       : 1px solid $secondary__color;
$button-secondary__color                        : $color-white;

$button-secondary__hover__background            : $primary__color;
$button-secondary__hover__border                : 1px solid $primary__color;
$button-secondary__hover__color                 : $button-secondary__color;

$button-secondary__active__background           : $button-secondary__hover__background;
$button-secondary__active__border               : $button-secondary__hover__border;
$button-secondary__active__color                : $button-secondary__hover__color;

//
//  Secondary Button
//  ---------------------------------------------

.action.secondary {
    @include lib-button(
        $_button-color                       : $button-secondary__color,
        $_button-background                  : $button-secondary__background,
        $_button-border                      : $button-secondary__border,
        $_button-color-hover                 : $button-secondary__hover__color,
        $_button-background-hover            : $button-secondary__hover__background,
        $_button-border-hover                : $button-secondary__hover__border,
        $_button-color-active                : $button-secondary__active__color,
        $_button-background-active           : $button-secondary__active__background,
        $_button-border-active               : $button-secondary__active__border
    );
}

a.action.secondary {
    @include lib-link-as-button();
}

//
//  Border Button Variables
//  ---------------------------------------------

$button-border__background                   : transparent;
$button-border__border                       : 2px solid $color-white;
$button-border__color                        : $color-white;

$button-border__hover__background            : rgba($primary__color, 0.7);
$button-border__hover__border                : 2px solid $secondary__color;
$button-border__hover__color                 : $secondary__color;

$button-border__active__background           : $button-border__hover__background;
$button-border__active__border               : $button-border__hover__border;
$button-border__active__color                : $button-border__hover__color;

//
//  Border Button
//  ---------------------------------------------

.action.border {
    @include lib-button(
        $_button-color                       : $button-border__color,
        $_button-background                  : $button-border__background,
        $_button-border                      : $button-border__border,
        $_button-color-hover                 : $button-border__hover__color,
        $_button-background-hover            : $button-border__hover__background,
        $_button-border-hover                : $button-border__hover__border,
        $_button-color-active                : $button-border__active__color,
        $_button-background-active           : $button-border__active__background,
        $_button-border-active               : $button-border__active__border,
        $_button-padding                     : 12px 50px,
        $_button-font-weight                 : 600
    );
}

a.action.border {
    @include lib-link-as-button();
}

//
//  Other
//  ---------------------------------------------

.action.fullwidth {
    width: 100%;
}

.action.large {
    padding: 16px 50px;
    font-weight: 600;
    white-space: nowrap;
}

.action.right {
    float: right;
}

.action.left {
    float: left;
}
