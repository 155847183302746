@import 'module/listings';
@import 'module/toolbar';
@import 'module/gallery';
@import 'module/gallery360';
/* Price styling changes by PT */

.gallery-placeholder {
    text-align: center;

    &:not(._block-content-loading) .gallery-placeholder__image {
        display: none;
    }
}

.loading-mask {
    display: none;
}

.added-to-basket {
    font-weight: bold;
    color: green;
    margin-top: $indent__base;
    font-size: 16px;
}

.call-us {
    font-size: 16px;
    margin: $indent__l 0;

    p {
        margin: 0;
    }
}

.fotorama__caption {
    display: none;
}

.description-above-pricing {
    margin-top: $indent__base;
}

//
//  Product Upsell
//  ---------------------------------------------

.block.upsell {
    @include max-screen($screen__l) {
        display: none;
    }
    .go-to-top {
        display: none;
    }
    .block-title {
        border-left: 1px solid #e7ecf2;
        text-transform: uppercase;
        margin: 0;
        padding: $indent__s 0 $indent__xl $indent__l;

        strong {
            font-size: 13px !important;
            font-weight: 500;
        }
    }
    .block-content {
        border-left: 1px solid #e7ecf2;
        padding-left: $indent__l;

        .products-grid {
            margin: 0;

            .product-items {
                padding: $indent__base 0 ($indent__xl + $indent__base);
                margin: 0;
            }
            .product-item-info {
                padding: 0;
                border: 0;
            }
        }
    }
    .slick-prev,
    .slick-next {
        left: 50%;
        right: auto;
        transform: rotate(90deg) translateX(-50%);
    }
    .slick-prev {
        top: 0;
        bottom: auto;
    }
    .slick-next {
        bottom: 0;
        top: auto;
    }
}

//
//  Product Details
//  ---------------------------------------------

.product-details {
    background-color: #ebf1f8;

    .page-main {
        padding-top: 0;
    }
}

//
//  Features
//  ---------------------------------------------

.features {
    background-color: $primary__color;
    padding-bottom: ($indent__l * 3);

    @include max-screen($screen__m) {
        padding-bottom: ($indent__l + $indent__base);
    }
    &-title {
        color: #8da5be;
        font-weight: 500;
        font-size: 19px;
        text-transform: uppercase;
        text-align: center;
        margin: $indent__l 0 ($indent__l * 2);

        @include max-screen($screen__m) {
            margin: $indent__m 0 $indent__l 0;
        }
    }
    &-list {
        display: none;
        @extend .abs-reset-list;
        padding: 0 20%;

        @include max-screen($screen__l) {
            padding: 0 10%;
        }
        @include max-screen($screen__m) {
            padding: 0;
        }
        &.slick-initialized {
            display: block;
        }
        .row {
            @include min-screen($screen__xs) {
                display: flex;
                align-items: center;
            }
        }
        img {
            width: 100%;

            @include max-screen($screen__xs) {
                margin-bottom: $indent__s;
            }
        }
        h3 {
            color: #ffffff;
            font-size: 23px;
            margin-top: $indent__s;
        }
        p {
            color: #8da5be;
            font-weight: 500;
            font-size: 15px;
        }
        .slick-dots {
            position: absolute;
            bottom: -71px;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            padding: 0;

            @include max-screen($screen__m) {
                bottom: -41px;
            }
            li {
                display: inline-block;
                margin: 0 7px;

                button {
                    display: block;
                    border: 0;
                    margin: 0;
                    padding: 0;
                    text-indent: -999em;
                    overflow: hidden;
                    width: 11px;
                    height: 11px;
                    border-radius: 50%;
                    background-color: #2b3f54;
                }
                &.slick-active {
                    button {
                        background-color: #8da5be;
                    }
                }
            }
        }
    }
    &-loading {
        color: #8da5be;
        text-align: center;
    }
}

//
//  Sharer
//  ---------------------------------------------

.sharer {
    @include lib-clearfix();
    margin-bottom: $indent__base;
    @include max-screen($screen__l) {
        margin-bottom: $indent__s;
    }
    a {
        @include lib-css(transition, color 0.2s, 1);
        float: left;
        margin-right: $indent__s;
        color: #112e42;
        font-size: 34px;
        &:hover {
            text-decoration: none;
            color: $secondary__color;
        }
        .fab,
        .fas {
            display: block;
        }
        span {
            display: none;
        }
    }
}

//
//  Category Top
//  ---------------------------------------------

.category-top,
.page-products .page-title-wrapper {
    position: relative;
    overflow: hidden;

    img {
        width: 100%;
        display: block;

        @include max-screen($screen__m) {
            width: auto;
            max-width: none;
            transform: translateX(-50%);
        }
    }
    .overlay {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    h1 {
        width: 100%;
        align-self: center;
        text-align: center;
        font-size: 36px;
        font-weight: 500;
        color: $color-white;
        margin: 0;

        @include max-screen($screen__l) {
            font-size: 30px;
        }
        @include max-screen($screen__m) {
            font-size: 24px;
        }
    }
    &.page-title-wrapper {
        h1 {
            color: $primary__color;
            border-bottom: 3px solid #eff2f6;
            padding-bottom: $indent__xl;
            margin-bottom: $indent__xl;

            @include max-screen($screen__xl) {
                padding-bottom: $indent__l;
                margin-bottom: $indent__l;
            }
            @include max-screen($screen__l) {
                padding-bottom: $indent__base;
                margin-bottom: $indent__base;
            }
            @include max-screen($screen__m) {
                padding-bottom: $indent__m;
                margin-bottom: $indent__m;
            }
        }
    }
}


//
//  Category view
//  ---------------------------------------------

.prices-tier {
    .price-container {
        .price-including-tax {
            + .price-excluding-tax {
                &:before {
                    content: '(' attr(data-label) ': ';
                }

                &:last-child:after {
                    content: ')';
                }
            }
        }

        .weee[data-label] {
            display: inline;

            .price {
                @include lib-font-size(11);
            }

            &:before {
                content: ' +' attr(data-label) ': ';
            }
        }
    }
}

.actual-price {
    font-weight: $font-weight__bold;
}

.category-view {
    @include make-row();

    .category-description {
        @include make-xxs-column(12);
        @include make-l-column(10);
        @include make-l-column-offset(1);
        @include make-xl-column(8);
        @include make-xl-column-offset(2);
        margin-bottom: $indent__l;

        @include max-screen($screen__xl) {
            margin-bottom: $indent__base;
        }
        @include max-screen($screen__l) {
            margin-bottom: $indent__m;
        }
        @include max-screen($screen__m) {
            margin-bottom: 0;
        }

        p {
            line-height: 1.75;

            @include max-screen($screen__m) {
                font-size: 13px;
            }
        }
    }
}

//
//  Product images general container
//  ---------------------------------------------

.product-image-container {
    display: block;
    max-width: 100%;
    width: auto !important;
}

.product-image-wrapper {
    display: block;
    height: 0;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.product-image-photo {
    bottom: 0;
    display: block;
    height: auto;
    left: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    right: 0;
    top: 0;
}

//
//  Product view
//  ---------------------------------------------

.product.media {
    position: relative;

    .product.photo .photo.image {
        @extend .abs-adaptive-images-centered;
    }

    .placeholder .photo.container {
        max-width: 100%;
    }

    .notice {
        @include lib-css(color, $text__color__muted);
        @include lib-font-size($font-size__s);
        margin: $indent__s 0;
    }

    .product.thumbs {
        margin: $indent__base 0 $indent__l;
    }

    .items.thumbs {
        @include lib-list-inline();

        .active {
            display: block;
            line-height: 1;
        }
    }
}

.product.info.detailed {
    clear: both;

    table {
        margin-bottom: $indent__base;
        tr {
            @include max-screen($screen__l) {
                border-bottom: 2px solid $secondary__color;
            }
            &:first-child {
                td, th {
                    border-bottom: 2px solid $secondary__color;
                    @include max-screen($screen__l) {
                        border: 0;
                    }
                }
            }
            &:last-child {
                td, th {
                    border-bottom: 0;
                }
            }
            td, th {
                border-bottom: 1px solid $secondary__color;
                @include max-screen($screen__l) {
                    display: block;
                    border: 0;
                }
            }
            th {
                border-right: 2px solid $secondary__color;
                @include max-screen($screen__l) {
                    border: 0;
                }
            }
        }
    }

    .additional-attributes {
        width: auto;
        @include lib-table-resize(
            $_th-padding-left   : 0,
            $_th-padding-right  : $indent__s,
            $_th-padding-bottom : $indent__s,
            $_td-padding-bottom : $indent__s
        );
    }
}

.product-info-main {
    @include min-screen($screen__m) {
        box-sizing: border-box;
        padding: 0 0 0 $indent__l;
    }
    @include min-screen($screen__l) {
        padding: 0 $indent__l;
    }
    .page-title-wrapper {
        .page-title {
            font-size: 26px;
            font-weight: 600;
            margin: 0;
        }
    }

    & > img {
        margin-bottom: $indent__xl;
        margin-top: $indent__s;
    }

    .product-info-stock-sku {
        margin: $indent__xs 0;

        .stock.available,
        .stock.unavailable {
            display: none;
        }
        .product.attribute.sku {
            color: #94acc6;

            .type {
                display: none;
            }
        }
    }

    .stock {
        &.available,
        &.unavailable {
            display: inline-block;
            font-weight: 500;
            vertical-align: top;
            margin: $indent__base 0;

            .fas {
                margin-right: 4px;
            }
        }
        &.available {
            color: #42af00;
        }
        &.unavailable {
            color: #f61717;
        }
    }

    .product {
        &.attribute {
            &.sku {
                display: inline-block;
                vertical-align: top;
                @include lib-css(color, $text__color__muted);

                > .value {
                    display: inline-block;
                    vertical-align: top;
                    word-break: break-all;
                }

                .type {
                    margin-right: $indent__xs;
                }
            }

            &.overview {
                margin: $indent__base 0;
            }
        }

        &.alert {
            margin: $indent__s 0;
        }
    }

    .product-reviews-summary .reviews-actions {
        @include lib-font-size(13);
        font-weight: 300;
    }
}

.product-options-wrapper {
    .fieldset-product-options-inner {
        .legend {
            @include lib-css(font-weight, $font-weight__bold);
            @include lib-css(margin, 0 0 $indent__xs);
            @include lib-font-size(14px);
            border: none;
            display: inline-block;
            float: none;
            padding: 0;
        }

        //  Date & Time custom option (Affect Time that goes only after Date)
        input.datetime-picker {
            ~ select.datetime-picker {
                margin-top: $indent__s;
            }
        }

        &.required,
        &._required {
            .legend {
                &:after {
                    content: '*';
                    @include lib-typography(
                        $_font-size   : $form-field-label-asterisk__font-size,
                        $_color       : $form-field-label-asterisk__color,
                        $_font-family : $form-field-label-asterisk__font-family,
                        $_font-weight : $form-field-label-asterisk__font-weight,
                        $_line-height : $form-field-label-asterisk__line-height,
                        $_font-style  : $form-field-label-asterisk__font-style
                    );
                    @include lib-css(margin, $form-field-label-asterisk__margin);
                }
            }
        }
    }

    .field {
        .note {
            display: block;
        }

        .price-notice {
            @extend .abs-adjustment-incl-excl-tax;
        }
    }
}

.product-info-main,
.product-options-bottom {
    .price-box {
        display: block;
        margin-top: $indent__base;
        @include lib-font-size(26);
        font-weight: 500;
        color: $tertiary__color;

        @include max-screen($screen__l) {
            margin-top: $indent__s;
        }
        .price-including-tax {
            &:after {
                content: ' ' attr(data-label);
                font-size: 75%;
            }
        }
        .price-excluding-tax {
            @include lib-font-size(16);
            font-weight: normal;

            &:before {
                content: '( ';
            }
            &:after {
                content: ' ' attr(data-label) ' )';
                font-size: 75%;
            }
        }
        .normal-price {
            .price-label {
                display: none !important;
            }
        }
        .old-price {
            @include lib-clearfix();
            display: block;
            @include lib-font-size(16);
            font-weight: normal;
            margin-top: $indent__xs;

            .price-label {
                display: none;
            }
            .price {
                text-decoration: line-through;
            }
            .price-excluding-tax {
                &:before {
                    content: 'Was ';
                }
                &:after {
                    content: ' ' attr(data-label);
                }
            }
            .price-including-tax {
                &:before {
                    content: 'Was ';
                }
                &:after {
                    content: ' ' attr(data-label);
                }
            }
        }
        .special-price {
            display: block;
            color: #f61717;

            .price-label {
                display: none;
            }
        }
    }

    .box-tocart {
        margin: $indent__base 0;
        @include max-screen($screen__l) {
            margin: $indent__s 0;
        }
        .label {
            font-size: 12px;
            font-weight: 400;
        }

        .qty {
            @include lib-clearfix();

            .label {
                display: none;
            }
            #qty {
                float: left;
                width: 60px;
                text-align: center;
                margin: 0 $indent__xs;
            }
            &-minus,
            &-plus {
                float: left;
                line-height: 40px;
                width: 50px;
                text-align: center;
                background-color: #dee5ee;
                color: $primary__color;
            }
        }

        .actions {
            margin-top: $indent__base;
            text-align: center;

            .action.tocart {
                font-size: 16px;
                font-weight: 500;
            }
        }

        .action.tocart {
            @extend .abs-button-l;
        }
    }

    .product-addto-links {
        margin: $indent__base 0;
    }

    .action.tocompare {
        @extend .abs-action-addto-product;
        vertical-align: top;
    }
}

.prices-tier {
    @extend .abs-reset-list;
    @include lib-css(background, $sidebar__background-color);
    margin-top: $indent__s;

    .price-container {
        display: inline-block;
    }

    .price-including-tax,
    .price-excluding-tax,
    .weee {
        display: inline-block;
        color: $tertiary__color;

        .price {
            @include lib-font-size(14);
        }
    }
    .price-including-tax {
        &:after {
            content: "inc VAT";
            margin-left: $indent__xs;
        }
    }
}

.ui-dialog-titlebar-close {
    @include lib-button-as-link();
}

.block.related {
    .action.select {
        margin: 0 $indent__xs;
    }
}

//
//  Sidebar product view
//  ---------------------------------------------

.sidebar {
    .product-items {
        .product-item {
            margin-bottom: $indent__base;
            position: relative;
        }

        .product-item-info {
            position: relative;
            width: auto;

            .product-item-photo {
                left: 0;
                position: absolute;
                top: 0;
            }
        }

        .product-item-name {
            margin-top: 0;
        }

        .product-item-details {
            margin: 0 0 0 85px;
        }

        .product-item-actions {
            display: block;
            margin-top: $indent__s;
        }

        .price-box {
            display: block;
            margin: 7px 0;
        }

        .text {
            margin-right: 8px;
        }

        .counter {
            @include lib-css(color, $primary__color__lighter);
            @include lib-font-size(12);
            white-space: nowrap;
        }

        .minilist {
            .price {
                display: inline;
                padding: 0;
            }

            .weee:before {
                display: inline-block;
            }
        }
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .subtitle {
        @extend .abs-no-display;
    }

    //
    //  Product images only
    //  ---------------------------------------------

    .product-items-images {
        @extend .abs-add-clearfix;
        margin-left: -$indent__xs;

        .product-item {
            @extend .abs-add-box-sizing;
            float: left;
            padding-left: $indent__xs;
        }
    }

    //
    //  Product names only
    //  ---------------------------------------------

    .product-items-names {
        .product-item {
            margin-bottom: $indent__s;
        }

        .product-item-name {
            margin: 0;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .catalog-product-view {
        .column.main {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-prefix-flex-direction(column);
        }

        .product.media {
            @include lib-vendor-prefix-order(-1);
            margin-bottom: $indent__base;
        }
    }

    .product-info-main .box-tocart {
        .actions {
            .action.tocart {
                @extend .abs-button-responsive-smaller;
            }
        }
    }

    .block.related {
        .action.select {
            display: block;
            margin: $indent__xs 0;
        }
    }

    .compare,
    .product-addto-links .action.tocompare,
    .product-item-actions .actions-secondary > .action.tocompare,
    [class*='block-compare'] {
        display: none;
    }
}

.product-add-form {
    .fieldset {
        & > .field {
            margin: 0 0 $indent__base;
            .label {
                font-weight: normal;
                font-size: 12px;
            }
        }
    }
}

.product-delivery-message {
    margin-top: $indent__base;
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product-info-main,
    .product-options-bottom {
        .box-tocart {

            .field.qty {

            }

            .actions {
                text-align: center;
                vertical-align: bottom;
            }
        }
    }

    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: left;
                    left: auto;
                    margin: 0 $indent__s $indent__s 0;
                    position: relative;
                    top: auto;
                }
            }

            .product-item-details {
                margin: 0;
            }

            .product-item-actions {
                clear: left;
            }
        }
    }

    .product-add-form {
        @extend .abs-revert-field-type-desktop;
    }
}

//
//  Desktop large
//  _____________________________________________

@include min-screen($screen__xl) {
    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: none;
                    left: 0;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }

            .product-item-details {
                margin-left: 85px;
            }
        }
    }
}

//
//  Category page layout
//  ---------------------------------------------

@include min-screen($screen__m) {
    .product-info-main {
        float: right;
    }

    .product.media {
        float: left;
    }

    .block.upsell {
        float: right;
    }
    .page-layout-1column {
        .product-info-main {
            width: 50%;
        }
        .product.media {
            width: 50%;
        }
    }
}

@include min-screen($screen__l) {
    .page-layout-1column {
        .product-info-main {
            width: 35%;
        }
        .product.media {
            width: 65%;
        }
        .block.upsell {
            width: 25%;

            ~ .product.media {
                width: 40%;
            }
        }
    }
}

@include min-screen($screen__xl) {
    .page-layout-1column {
        .product-info-main {
            width: 35%;
        }
        .product.media {
            width: 65%;
        }
        .block.upsell {
            width: 15%;

            ~ .product.media {
                width: 50%;
            }
        }
    }
}

//
//  Compare Products Page
//  ---------------------------------------------

body.catalog-product-compare-index {
    .action.print {
        float: right;
        margin: 15px 0;
    }
}

.table-wrapper.comparison {
    clear: both;
    max-width: 100%;
    overflow-x: auto;
}

.table-comparison {
    table-layout: fixed;

    .cell.label.remove,
    .cell.label.product {
        span {
            @extend .abs-visually-hidden;
        }
    }

    .cell.label,
    td:last-child {
        border-right: $table__border-width $table__border-style $table__border-color;
    }

    .cell {
        padding: 15px;
        width: 140px;

        .attribute.value {
            overflow: hidden;
            width: 100%;
        }

        &.product.info,
        &.product.label {
            border-bottom: $table__border-width $table__border-style $table__border-color;
        }

        &.label {
            .attribute.label {
                display: block;
                width: 100%;
                word-wrap: break-word;
            }
        }

        &.attribute {
            @include lib-font-size(13);
            img {
                height: auto;
                max-width: 100%;
            }
        }
    }

    .product-item-photo {
        display: block;
        margin: 0 auto 15px;
    }

    .product-image-photo {
        margin-left: 0;
    }

    .product-item-actions,
    .price-box,
    .product.rating,
    .product-item-name {
        display: block;
        margin: 15px 0;
    }

    .product-addto-links {
        margin-top: 15px;

        .action.split,
        .action.toggle {
            @include lib-button-s();
        }

        .action.toggle {
            padding: 0;
        }
    }

    .cell.remove {
        padding-bottom: 0;
        padding-top: 0;
        text-align: right;

        .action.delete {
            @extend .abs-remove-button-for-blocks;
        }
    }

    .product-item-actions {
        > .actions-primary {
            + .actions-secondary {
                margin-top: $indent__s;
            }
        }
    }

    .action {
        &.tocart {
            white-space: nowrap;
        }
    }
}

.comparison.headings {
    @include lib-css(background, $page__background-color);
    left: 0;
    position: absolute;
    top: 0;
    width: auto;
    z-index: 2;
}

.block-compare {
    .block-title {
        @extend .abs-block-title;
    }

    .product-item .product-item-name {
        margin-left: 22px;
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            left: -6px;
            position: absolute;
            top: 0;
        }

        &.compare {
            @extend .abs-revert-secondary-color;
        }
    }

    .counter {
        @extend .abs-block-items-counter;
    }

    .actions-toolbar {
        margin: 17px 0 0;
    }
}
