//
//  Variables
//  _____________________________________________

$checkout-wrapper__margin              : $indent__base;
$checkout-wrapper__columns             : 8;

$checkout-step-title__border           : $border-width__base solid $color-gray80;
$checkout-step-title__font-size        : 26px;
$checkout-step-title__font-weight      : $font-weight__light;
$checkout-step-title__padding          : $indent__s;

$checkout-step-title-mobile__font-size : 18px;

.checkout-index-index {
    .page-title-wrapper {
        @extend .abs-visually-hidden;
    }
}

.action.action-auth-toggle {
    @include lib-button-primary();
}

.checkout-container {
    @extend .abs-add-clearfix;
    @include lib-css(margin, 0 0 $checkout-wrapper__margin);
}

.opc-wrapper {
    @include lib-css(margin, 0 0 $checkout-wrapper__margin);

    .opc {
        @extend .abs-reset-list;
    }

    .step-title {
        @extend .abs-checkout-title;
    }

    .step-content {
        margin: 0 0 $indent__xl;
    }
}

.checkout-index-index {
    .nav-sections,
    .nav-toggle {
        display: none;
    }

    .logo {
        margin-left: 0;
    }
}

.checkout-onepage-success {
    @extend .abs-add-clearfix;

    .print {
        display: none;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .opc-wrapper {
        .step-title {
            @include lib-css(font-size, $checkout-step-title-mobile__font-size);
            border-bottom: 0;
            padding-bottom: 0;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-wrapper {
        @extend .abs-add-box-sizing-desktop-m;
        @include lib-layout-column(2, 1, $checkout-wrapper__columns);
        padding-right: $indent__l;
    }

    .checkout-onepage-success {
        .print {
            display: block;
            float: right;
            margin: 22px 0 0;
        }
    }
}
