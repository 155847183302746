//
//  Fonts
//  ----------------------------------------------

$font-path: "../webfonts" !default;

/* Coalition */

@font-face {
  font-family: "Coalition";
  font-style: normal;
  font-weight: normal;
  src: url('#{$font-path}/coalition.eot');
  src: url('#{$font-path}/coalition.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/coalition.woff2') format('woff2'),
  url('#{$font-path}/coalition.woff') format('woff'),
  url('#{$font-path}/coalition.ttf') format('truetype');
}

/* Bank Gothic BT */

@font-face {
  font-family: "Bank Gothic BT";
  font-style: normal;
  font-weight: 500;
  src: url('#{$font-path}/bank-gothic-medium-bt.eot');
  src: url('#{$font-path}/bank-gothic-medium-bt.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/bank-gothic-medium-bt.woff2') format('woff2'),
  url('#{$font-path}/bank-gothic-medium-bt.woff') format('woff'),
  url('#{$font-path}/bank-gothic-medium-bt.ttf') format('truetype');
}
