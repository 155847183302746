//
//  Newsletter
//  ---------------------------------------------

.block.newsletter {
    margin-bottom: 0;
    .title {
        display: none;
    }
    .content {
        .form.subscribe {
            display: table;
            width: 100%;
            .field.newsletter {
                .label {
                    @extend .abs-visually-hidden;
                }
                .control {
                    #newsletter {
                        border-color: $footer-background-dark__color;
                        background-color: $footer-background-dark__color;
                        color: $footer-text__color;
                        font-weight: normal;
                        font-size: 12px;
                        height: 45px;
                    }
                }
            }
            .actions {
                width: 1%;
                display: table-cell;
                vertical-align: top;
                .action.subscribe {
                    text-transform: none;
                    border-color: $footer-background-light__color;
                    background-color: $footer-background-light__color;
                    color: $footer-text__color;
                    padding-top: 14px;
                    padding-bottom: 13px;
                    &:hover {
                        background-color: $secondary__color;
                        color: $primary__color;
                    }
                }
            }
            .field-recaptcha {
                display: table-row;
            }
        }
    }
}
